@import "../../global.scss";

.intro {
  background-color: $mainColor;
  display: flex;
  color: white;

  @include mobile{
    flex-direction: column;
    align-items: center;
  }

  h1{
    font-size: 60px;
    font-family: 'WindSong', cursive !important;  
    text-transform: none !important;
  }

  .intro-carousel{
    height: calc(100vh - 70px);
    width: 100%;

    .pic{
      max-height: calc(100vh - 70px);
      object-fit: cover;
    }
  }


    .wrapper {
      width: 100%;
      height: 100%;
      padding-top: calc(100vh - 300px);
      display: flex;
      flex-direction: column;
      position: absolute;
    z-index: 2;
    text-align: center;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));

      @include mobile{
        padding-left: 0;
        align-items: center;
      }

     
      h3 {
        font-size: 30px;

        @include mobile{
          font-size: 15px;
        }

        
      }}
    
 
  }

