@import "../../global.scss";

.footer {
  width: 100%;
  height: 70px;
  display: flex;
  color: white;
  position: fixed;
  bottom: 0;
  z-index: 3;
  overflow: hidden;

  .icon-container{
    background-color: #BB9981;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;

    &:hover{
      text-decoration:none !important;
    }

    i{
      color: white;

      
    }
  }
 
}
