@import "../../global.scss";

.aboutMe {
  background-color: $mainColor;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;

  h1 {
color: white;
   
  }

  @include mobile{
    flex-direction: column;
    align-items: center;
  }

  .title-line{
    width: 35%;
    height: 2px;
    background-color: white;
    @include mobile{
      height: 1px;
      width: 20%;
    }
  }

  .light{
    background-color: RGB(222, 207, 193);
    color: black;

    @include mobile{
      flex-direction: column-reverse !important;
    }
  }

  div{
    font-size: 14px;
  }

  .left {
    flex: 0.8;
    position: relative;
    @include mobile{
      z-index: 100;
    }

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      padding-right: 50px;  
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile{
        align-items: center;
      }

      }
    
  }
  .right {
    flex: 0.5;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 1rem;

    @include mobile{
    }

    .imgContainer {
      width: 450px;
      height: 450px;
      margin-left: 2rem;
     // margin-bottom: 12rem;      
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


      @include mobile{
        align-items: flex-start;
        width: 350px;
        height: 350px;
        margin: auto;
      }

      img {
        height: 100%;
        width: 100%;
    object-fit: cover;

      }
    }

    
    
    a {
      position: absolute;
      bottom: 10px;
      left: 40%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
