@import "../../global.scss";

.portfolio {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $mainColor;

  .title-line{
    width: 35%;
    height: 2px;
    background-color: black;
    @include mobile{
      height: 1px;
      width: 20%;
    }
  }



  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile{
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .container {
    width: 80%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    min-height: 500px;

    @include mobile{
      width: 100%;
    }

    div{
      min-height: 450px;
      overflow: hidden;

    }

    .item {
      width: 350px;
      height: 350px;
      border-radius: 50%;
      border: 0px solid rgb(240, 239, 239);
      margin: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all .5s ease;
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      @include mobile{
        width: 200px;
        height: 200px;
      }

      h3 {
        position: absolute;
        font-size: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }

      &:hover {
        transform: scale(1.1);
        background-color: $mainColor;
        img {
          opacity: 0.6;
          z-index: 0;
        }
      }
    }
  }
}
