@import "../../global.scss";

.collaborators {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;

  .main-picture{
    width: 120px;
    min-width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;

    @include mobile{
      margin: auto;

    }
  }

  .container-list{
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
  
  }

  .arrow {
    height: 50px;
    position: absolute;
    cursor: pointer;

    @include mobile {
      display: none;
    }

    &.left {
      left: 100px;
      transform: rotateY(180deg);
    }

    &.right {
      right: 100px;
    }
  }

  .title-line{
    width: 35%;
    height: 2px;
    background-color: black;
    @include mobile{
      height: 1px;
      width: 20%;
    }
  }

    .item-container {
      width: 50vw;
      display: flex;
      align-items: center;
      justify-content: center;
      &.pink{
      background-color: RGB(222, 207, 193);
      @include mobile {
        background-color: white;
      }
    }

    &.pink-mobile{
      @include mobile {
        background-color: RGB(222, 207, 193);
      }
    }

      @include mobile{
        width: 100vw;
        flex-direction: column-reverse;
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        @include mobile {
          flex-direction: column;
          margin-bottom: 1rem;
          margin-top: 1rem;
        }

        .left {
          flex: 8;
          display: flex;
          align-items: center;
          justify-content: center;

          @include mobile{
            flex: none;
          }

          .leftContainer {
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include mobile{
              text-align: center;
              margin-left: 1rem;
              margin-right: 1rem;
            }
     
      
          }
        }

        .bottom-picture{
          @include mobile{
            display: none !important;
          }        }

        .d-none-picture{
          display: none !important;
          @include mobile{
            display: flex !important;
            flex-direction: column;
          }
        }
        .right {
          flex: 4;
          @include mobile{
            flex: none;
            flex-direction: column;

          }

          padding: 0.5rem;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

        }
      }
    
  }
  .icon-list{
    @include mobile{
display: flex;    
}
  }
  .icon-container{
    background-color: #BB9981;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 5px;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;

    &:hover{
      text-decoration:none !important;
    }

    i{
      color: white;

      
    }

    
  }
}
