@import "../../global.scss";

.contact {
  background-color: white;
  display: flex;
  color: $mainColor;

  @include mobile {
    flex-direction: column;
  }

  .title-line{
    width: 35%;
    height: 2px;
    background-color: black;
    @include mobile{
      height: 1px;
      width: 20%;
    }
  }

  .left {
    flex: 1;
    overflow: hidden;

    img {
      height: 100%;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 30px;
    }

    form {
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      input {
        width: 300px;
        height: 30px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          margin-bottom: 1rem;
        }
      }

      textarea {
        width: 300px;
        height: 200px;
        font-size: 14px;

        @include mobile {
          width: 200px;
          height: 100px;
          margin-bottom: 1rem;

        }
      }

    }
  }
}
