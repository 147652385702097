@import "../../global.scss";

.videoPack {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 70px;

  
  .icon-container{
    background-color: #192F35;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;

    &:hover{
      text-decoration:none !important;
    }

    i{
      color: white;

      
    }
  }

  h1{
    @include mobile{
      text-align: center;
      font-size: 30px;
      line-height: 40px;
    }
  }

  .image-cont{
    max-height: 120px;
    object-fit: cover;
  }

  .pack{
    width: 300px;

    @include mobile{
      width: 100%;
    }
    .pic{
      max-height: 400px;
      object-fit: cover;
    }

    .carousel-caption{
      right: 0px;
      left: 0px;
      bottom: 0px;
      background-color: #00000077;
    }
  }

  .card-list{
    margin-top: 4rem;
    justify-content: space-between;
    width: 70%;
    flex-wrap: wrap;
    max-width: 800px;

    @include mobile{
      justify-content: center;
    }

    .card-item{
      box-shadow: 0px 0px 15px -8px black;
border-radius: 10px;
    }

  }

  .wrapper {
    margin-top: 4rem;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;}


  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile{
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile{
      width: 100%;
    }

    .item {
      width: 220px;
      height: 150px;
      border-radius: 20px;
      border: 1px solid rgb(240, 239, 239);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all .5s ease;
      cursor: pointer;

      @include mobile{
        width: 130px;
        height: 100px;
      }

      h3 {
        position: absolute;
        font-size: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }

      &:hover {
        background-color: $mainColor;
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}

.modal-body{
  white-space: pre-line;
  font-size: 14px;
  img{
    max-height: 300px;
    object-fit: cover;
  }
}
