.app{
    height: 100vh;

    body{
        font-size: 12px;
    }

    h5{
        line-height: 30px;
    }
    .h5{
        line-height: 30px;
    }

    h4{
        line-height: 40px;
    }

    h3{
        line-height: 45px;
    }

    .button{
        width: 150px;
        height: 30px;
        color: white;
        font-family: 'Scope One', serif;
        text-transform: uppercase;
        font-size: 16px;

        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    button{
        background-color: #BB9981;
        border: none;

       
        


        &:hover{
            background-color: RGB(222, 207, 193);
        }
        &:focus{
            border-color: RGB(222, 207, 193);
            box-shadow: none !important;
        }
        &:active{
            background-color: #BB9981 !important;
            border: none;

        }
    }

    .text-overline{
        font-family: 'Scope One', serif;
        text-transform: uppercase;
        font-size: 11px;
    }

    h1{
        font-family: 'Scope One', serif;
    text-transform: uppercase;
        line-height: 100px;
        padding: 0 10px;
        overflow: hidden;
        @media (max-width: 768px){
            font-size: 35px;
    
        }
      }

    h2{
        font-family: 'WindSong', cursive;  
        line-height: 80px;
        padding: 0 10px;
        overflow: hidden;
      }

    .sections{
        width: 100%;
        height: calc(100vh - 70px);
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none; //for firefox
        &::-webkit-scrollbar{
            display: none;
        }

        > *{
            width: 100vw;
            min-height: calc(100vh - 70px);
            scroll-snap-align: start;
        }

        @media (max-width: 768px){
            scroll-snap-type:none;
            height: auto;

            > *{
                width: 100vw;
                scroll-snap-align: none;
                height: auto;
                }
    
        }
    }

    
}