
$mainColor: #09080D;
$darkGray: lightsalmon;

$width: 768px;

@mixin mobile {
    @media (max-width: #{$width}){
        @content
    }
};

@mixin desktop {
    @media (min-width: #{$width}){
        @content
    }
};