@import "../../global.scss";

.photoPackages {
  background-color: $mainColor;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile{
    justify-content: space-around;
  }
  .title-line{
    width: 35%;
    height: 2px;
    background-color: white;
    @include mobile{
      height: 1px;
      width: 20%;
    }
  }

  h1 {
    color: white;
   
  }

  .container {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;

    @include mobile{
      flex-direction: column;
      height: 100%;
    }

    .card {
      width: 250px;
      height: 70%;
      border-radius: 10px;
      background-color: white;
      box-shadow: 0px 0px 15px -8px white;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      transition: all 1s ease;
      cursor: pointer;
      color: black !important;
    text-decoration: none;

      @include mobile{
        margin: 10px 0;
      }

      &.featured{
          width: 350px;
          height: 80%;
          margin: 0 30px;

          @include mobile{
            width: 250px;
            margin: 1px;
          }
      }

      &:hover{
          transform: scale(1.1);
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          
        }
      }

      .center {
        padding: 10px;
        border-radius: 10px;
        background-color: rgb(250, 244, 245);

        @include mobile{
          font-size: 12px;
          padding: 5px;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        h3 {
          margin-bottom: 5px;

          @include mobile{
            font-size: 14px;
          }
        }

        div{
          @include mobile{
            display: none;
          }
        }
       
      }
    }
  }
}

.button{
  width: 150px;
  height: 30px;
  color: white;
  font-family: 'Scope One', serif;
  text-transform: uppercase;
  font-size: 16px;
  align-items: center;

  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  background-color: #BB9981;
  border: none;

 &.close{
  color: #BB9981;
  font-weight: 800;
background-color: white;
border:2px solid #BB9981
 }
  


  &:hover{
      background-color: RGB(222, 207, 193);
  }
  &:focus{
      border-color: RGB(222, 207, 193);
      box-shadow: none !important;
  }
  &:active{
      background-color: #BB9981 !important;
      border: none;

  }

}


